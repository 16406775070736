import * as merchants from './merchants';
import * as users from './users';
import * as transactions from './transactions';
import * as native from './native';
import * as secretkey from './secretkey';
import {API} from 'aws-amplify';


const appSyncQuery = (query, variables = {}, internal) => {
    // Lets us swap between our two endpoints for calling our internal and external APIs
    if (internal) {
        API._options.aws_appsync_graphqlEndpoint = API._options.endpoints[1]
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.endpoints[1]
        API._graphqlApi._api._options.aws_appsync_graphqlEndpoint = API._options.endpoints[1]
    } else {
        API._options.aws_appsync_graphqlEndpoint = API._options.endpoints[0]
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.endpoints[0]
        API._graphqlApi._api._options.aws_appsync_graphqlEndpoint = API._options.endpoints[0]
    }
    return API.graphql({query, variables});
};


export {merchants, users, transactions, appSyncQuery, secretkey, native};
